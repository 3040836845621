<template>
  <base-drawer-dialog
    v-model="dialog"
    title="Обработчик активности"
  >
    <v-form
      ref="form"
      v-model="valid"
    >
      <v-row>
        <v-col>
          <BaseDialogFieldBlock
            title="Действие обработчика"
            description="Выберите, какое действие будет выполнять обработчик"
          >
            <v-select
              v-model="model.action_type"
              class=""
              :items="avaibleActionTypeList"
              item-text="text"
              item-value="id"
              item-disabled="disabled"
              placeholder="Выберите действие"
              outlined
              :rules="[
                v => !!v || 'Выберите действие',
              ]"
              @change="onChangeAction"
            />
          </BaseDialogFieldBlock>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <BaseDialogFieldBlock
            title="Ответственный сотрудник"
            description="Укажите сотрудника от имени которого будут выполняться действия"
          >
            <v-row>
              <v-col>
                <v-row>
                  <v-col>
                    <v-radio-group
                      v-model="isDefaultRunUserComp"
                      class="mt-0"
                      hide-details
                    >
                      <v-radio
                        label="Создатель"
                        :value="true"
                      />
                      <v-radio
                        label="Другой сотрудник (выбрать из списка)"
                        :value="false"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-row v-if="!isDefaultRunUserComp">
                  <v-col>
                    <employee-select
                      v-model="model.run_user_id"
                      :rules="[
                        v=> !!v || 'Выберите сотрудника'
                      ]"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </BaseDialogFieldBlock>
        </v-col>
      </v-row>
      <handler-segment-action
        v-if="[ACTION_ENUM.SEGMENT_IN.id, ACTION_ENUM.SEGMENT_OUT.id].includes(model.action_type)"
        v-model="model"
      />
      <handler-notification-action
        v-else-if="model.action_type === ACTION_ENUM.NOTIFICATION.id"
        v-model="model"
      />
      <handler-bonus-action
        v-else-if="[ACTION_ENUM.BONUS_DEBIT.id, ACTION_ENUM.BONUS_CREDIT.id, ACTION_ENUM.SALE_BONUS_CREDIT.id].includes(model.action_type)"
        v-model="model"
      />

      <v-row
        justify="space-between"
        align="center"
      >
        <v-col>
          <v-btn
            :disabled="!valid"
            color="primary"
            :loading="saveHandlerAction"
            @click="saveHandlerClick()"
          >
            <v-icon left>
              $iconify_ion-checkmark-circle-outline
            </v-icon>Сохранить
          </v-btn>
        </v-col>
        <v-col v-if="model.id && !model.deleted_at">
          <v-btn
            text
            color="error"
            :loading="deleteAction"
            @click="deleteClick"
          >
            <v-icon left>
              $iconify_feather-trash
            </v-icon>
            <span>Удалить</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </base-drawer-dialog>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import dialogable from '@/mixins/dialogable.js'
  import ProgramEventBroadcasterHandler from '@/models/program/broadcasterHandler'
  import ProgramEventBroadcaster from '@/models/program/broadcaster'

  export default {
    components: {
      HandlerSegmentAction: () => import('./HandlerSegmentAction'),
      HandlerNotificationAction: () => import('./HandlerNotificationAction'),
      HandlerBonusAction: () => import('./HandlerBonusAction'),
      EmployeeSelect: () => import('@/components/ModelSelect/EmployeeSelect'),
    },
    mixins: [dialogable],
    props: {
      model: {
        type: Object,
        required: true,
      },
      broadcaster: {
        type: Object,
        default: undefined,
      },
    },
    constants: {
      actionTypeList: ProgramEventBroadcasterHandler.ACTION_ENUM.toList(),
      ACTION_ENUM: ProgramEventBroadcasterHandler.ACTION_ENUM,
      EMIT_MODE_ENUM: ProgramEventBroadcaster.EMIT_MODE_ENUM,
    },
    data () {
      return {
        valid: false,
        saveHandlerAction: false,
        deleteAction: false,
        getSegmenPickListAction: false,
        getNotifyPickListAction: false,
        isDefaultRunUser: true,

      }
    },
    computed: {
      ...mapGetters('crm/segment', ['pickSegments']),
      ...mapGetters('company/notifications', ['notificationPickList']),
      avaibleActionTypeList () {
        if (this.broadcaster) {
          return this.actionTypeList.filter(a => a.id !== this.ACTION_ENUM.SALE_BONUS_CREDIT.id ||
            (this.broadcaster.emit_mode === this.EMIT_MODE_ENUM.EVENT.id && ProgramEventBroadcaster.SALE_LISTEN_EVENT.includes(this.broadcaster.listen_event)))
        } else {
          return this.actionTypeList
        }
      },
      isDefaultRunUserComp: {
        get: function () {
          return this.model.run_user_id == null && this.isDefaultRunUser
        },
        set: function (v) {
          this.isDefaultRunUser = v
          if (v) this.model.run_user_id = null
        },
      },
    },
    mounted () {
      console.log('eventHandlerDialog-mounted', this.model)
      // this.loadPickSegments()
      // this.loadPickNotify()
    },
    methods: {
      ...mapActions({
        getSegmentPickList: 'crm/segment/getPickList',
        getNotifyPickList: 'company/notifications/getPickList',
        CreateBroadcasterHandler: 'company/event_broadcasters/CreateBroadcasterHandler',
        UpdateBroadcasterHandler: 'company/event_broadcasters/UpdateBroadcasterHandler',
        DeleteBroadcasterHandler: 'company/event_broadcasters/DeleteBroadcasterHandler',
      }),

      onChangeAction () {
        this.model.action_json = {}
      },

      async loadPickSegments () {
        try {
          this.getSegmenPickListAction = true
          await this.getSegmentPickList(this.model.program_id)
        } catch (e) {
          console.error(e)
        } finally {
          this.getSegmenPickListAction = false
        }
      },
      async loadPickNotify () {
        try {
          this.getNotifyPickListAction = true
          await this.getNotifyPickList(this.model.program_id)
        } catch (e) {
          console.error(e)
        } finally {
          this.getNotifyPickListAction = false
        }
      },
      async deleteClick () {
        try {
          this.deleteAction = true
          if (this.model.broadcaster_id && this.model.id) {
            await this.DeleteBroadcasterHandler(this.model.id)
          } else {

          }
          this.close()
          this.$emit('delete', this.model)
        } catch (e) {

        } finally {
          this.deleteAction = false
        }
      },
      async saveHandlerClick () {
        if (!this.$refs.form.validate()) return

        try {
          this.saveHandlerAction = true
          let savedModel = this.model
          if (this.model.broadcaster_id) {
            if (this.model.isNew) {
              savedModel = await this.CreateBroadcasterHandler(Object.assign({ name: this.$uuid() }, this.model))
            } else {
              savedModel = await this.UpdateBroadcasterHandler(Object.assign({ handler_id: this.model.id }, this.model))
            }
          }
          this.close()
          this.$emit('save', savedModel)
        } catch (error) {
          console.error(error)
        } finally {
          this.saveHandlerAction = false
        }
      },

    },
  }
</script>

<style lang="scss" scoped>
.dialog-header{
  padding: 34px;
}
.dialog-body {
  padding: 34px;
  padding-right: 34px;
}
.cert-payment-select {
  margin-top: 14px;;
  margin-bottom: 14px;
}
</style>
